// extracted by mini-css-extract-plugin
export var buttonsContainer = "ReviewPopup-module--buttonsContainer--56170";
export var closeButton = "ReviewPopup-module--closeButton--0b68b";
export var commentBox = "ReviewPopup-module--commentBox--40efd";
export var orderDate = "ReviewPopup-module--orderDate--773c6";
export var orderItems = "ReviewPopup-module--orderItems--cd0d6";
export var orderSummary = "ReviewPopup-module--orderSummary--47d65";
export var overlay = "ReviewPopup-module--overlay--4f7f9";
export var popupContainer = "ReviewPopup-module--popupContainer--6be47";
export var starButton = "ReviewPopup-module--starButton--41f99";
export var starsContainer = "ReviewPopup-module--starsContainer--e59ad";
export var thankYouContainer = "ReviewPopup-module--thankYouContainer--83d14";
export var thankYouText = "ReviewPopup-module--thankYouText--2127f";
export var title = "ReviewPopup-module--title--b27a1";