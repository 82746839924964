import postWithTextResponse from "../utils/postWithTextResponse";
import { post, get, deleteMethod } from "../utils/rest";
import SERVICE from "./constants";

export default class ReviewAPI {
  constructor(user, businessId) {
    this.businessId = businessId || process.env.GATSBY_BUSINESS_ID;
    this.user = user;
    if (user && user.userId) {
      this.authHeaders = {
        SESSION_ID: user.secretGUID,
        BUSINESS_ID: this.businessId,
        USER_ID: user.userId,
        MAC_ADDRESS: user.uniqueIdentifier,
      };
    }
  }

  getReviewStatus = () =>
    get(`${SERVICE.REVIEW}/getReviewStatusFromServer?userId=${this.user.userId}&businessId=${this.businessId}`, {
    }, this.authHeaders);

  submitScore = ({ score, comment, id }) =>
    post(`${SERVICE.REVIEW}/handleReviewSubmissionToServer`, {
      score,
      comment,
      id,
    }, this.authHeaders);

  skipReview = ({ reviewId }) => {
    return post(`${SERVICE.REVIEW}/handleReviewSkipToServer?id=${reviewId}`, {
    },
    this.authHeaders
  );
  } 
}
