import React from "react";
import { Button } from "@mui/material";
import ScanInStoreIcon from "./svg/scan-in-store.svg";

const ScanInStoreButton = ({
  T,
  appStyles,
  style = {},
  withNavBar,
  onClick,
  businessAppConfiguration
}) => {
  const { disabledFeatures } = appStyles;
  const { separateQRCodeForPayment } = businessAppConfiguration;
  if ((disabledFeatures && disabledFeatures.includes("scan")) 
     || !separateQRCodeForPayment) {
    return null;
  }
  console.log("button style", style);

  return (
    <Button
      startIcon={<ScanInStoreIcon />}
      variant="contained"
      sx={{
        color: "#ffffff",
        ...appStyles.Button,
        position: "fixed",
        // appBar height + 12px margin
        bottom: (withNavBar ? 72 : 0) + 12,
        right: 12,
        borderRadius: "23px",
        padding: "15px 18px",
        fontSize: 18,
        textTransform: "none",
        fontWeight: 700,
        backgroundColor: appStyles.actionColor,
        zIndex: 1000,
        ...style,
      }}
      onClick={onClick}
    >
      {T("Scan in store")}
    </Button>
  );
};

export default ScanInStoreButton;
