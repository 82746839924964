import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import {getStyles} from "../../store/selectors";
import ReviewAPI from '../../api-services/review';
import ReviewPopup from './ReviewPopup';

function Review({ appStyles, user, business }) {

  const [reviewStatus, setReviewStatus] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  
  async function getReviewStatusFromServer() {
    if (!user || !business || !user.userId) {
      return null;
    }
    const reviewAPI = new ReviewAPI(user, business.id);
    const status = await reviewAPI.getReviewStatus();
    console.log('Received review status', status);
    return status;
  }
  
  
  async function handleReviewSubmissionToServer({ score, comment }) {
    const reviewAPI = new ReviewAPI(user, business.id);
    try {
      await reviewAPI.submitScore({ score, comment, id: reviewStatus.id });
      setIsOpen(false);
    } catch(e) {
      console.error('Error in handleReviewSubmissionToServer', e);
    }
  }
  
  
  async function handleReviewSkipToServer() {
    const reviewAPI = new ReviewAPI(user, business.id);
    try {
      await reviewAPI.skipReview({ reviewId: reviewStatus.id });
      setIsOpen(false);
    } catch(e) {
      console.error('Error in handleReviewSkipToServer', e);
      setIsOpen(false);
    }
  }
  

  async function handleClose() {
    console.log('Review handleClose');
    setIsOpen(false);
  }

  async function handleSkip() {
    console.log('Review handleSkip');
    setIsOpen(false);
    try {
      await handleReviewSkipToServer();
    } catch(e) {
      console.error('Error in handleReviewSkipToServer', e);
    }
  }

  async function handleSubmit({score, comment}) {
    console.log('Review handleSubmit', { score, comment });
    try {
      await handleReviewSubmissionToServer({ score, comment });
    } catch(e) {
      console.error('Error in handleReviewSubmissionToServer', e);
    }
    
    setIsOpen(true);
  }

  useEffect(() => {
    getReviewStatusFromServer().then((status) => {
      if (status) {
        setIsOpen(true);
        setReviewStatus(status);  
      }
    }).catch(e => console.error('Error in getReviewStatusFromServer', e));
  }, []);

  return reviewStatus ? <ReviewPopup isOpen={isOpen} onSkip={handleSkip} onSubmit={handleSubmit} onClose={handleClose} reviewStatus={reviewStatus}  /> : null;
}

const mapStateToProps = (state, props) => {
  const {
    user,
  } = state;

  return { user, appStyles: getStyles(state, props) };
};

const mapDispatchToProps = (dispatch, { context }) => {
  return {}
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Review);
