import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Button,
} from "@mui/material";
import * as styles from './ReviewPopup.module.scss';
import { connect } from "react-redux";
import {getStyles} from "../../store/selectors";
import Loader from '../Loader';


const formatItemsList = (items) => {
  if (!items || items.length === 0) return '';
  
  if (items.length <= 2) {
    return items.map(item => item.description).join(', ');
  }
  
  if (items.length === 3) {
    return `${items[0].description}, ${items[1].description} and one more...`;
  }
  
  return `${items[0].description}, ${items[1].description}, and ${items.length - 2} more...`;
};

const ReviewPopup = ({ 
  isOpen, 
  reviewStatus,
  onSkip,
  onClose,
  onSubmit,
  appStyles,
  isSubmitting,
}) => {
  const [score, setScore] = useState(0);
  const [comment, setComment] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit?.({ score, comment });
    setIsSubmitted(true);
  };

  if (isSubmitted) {
    return (
      <div className={styles.overlay} onClick={onClose}>
        <div 
          className={styles.popupContainer} 
          onClick={e => e.stopPropagation()}
        >
          <button 
            className={styles.closeButton}
            onClick={onSkip}
          >
            <CloseIcon sx={{ fontSize: 24 }} />
          </button>

          <div className={styles.thankYouContainer}>
            <CheckCircleIcon 
              sx={{ 
                fontSize: 48, 
                color: appStyles?.accentColor || 'green',
                marginBottom: '16px'
              }} 
            />
            <h2 className={styles.title} style={{ color: appStyles?.accentColor }}>
              Thank you for your review!
            </h2>
            <p className={styles.thankYouText}>
              We appreciate your feedback.
            </p>
            <Button
              onClick={onClose}
              color="primary"
              variant="contained"
              style={{ marginTop: '16px' }}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  };

  return (
    <div className={styles.overlay} onClick={onSkip}>
      <div 
        className={styles.popupContainer} 
        onClick={e => e.stopPropagation()}
      >
        <button 
          className={styles.closeButton}
          onClick={onSkip}
        >
          <CloseIcon sx={{ fontSize: 24 }} />
        </button>
        
        <h2 className={styles.title} style={{ color: appStyles?.accentColor }}>
          How was your experience?
        </h2>
        <div className={styles.orderDetails}>
          <p className={styles.orderSummary}>Your order of:</p>
          <p className={styles.orderItems} style={{ textAlign: 'center' }}>{formatItemsList(reviewStatus.items)}</p>
          <p className={styles.orderDate}>{formatDate(reviewStatus.orderDate)}</p>
        </div>

        <div className={styles.starsContainer}>
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              className={styles.starButton}
              onClick={() => setScore(star)}
            >
              {star <= score ? (
                <Star sx={{ 
                  fontSize: 32,
                  color: appStyles?.accentColor || '#FFD700'
                }} />
              ) : (
                <StarBorder sx={{ 
                  fontSize: 32,
                  color: '#DDD'
                }} />
              )}
            </button>
          ))}
        </div>

        <textarea
          className={styles.commentBox}
          placeholder="Tell us more about your experience..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          rows={3}
        />

        <div className={styles.buttonsContainer}>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={isSubmitting}
          >
            {isSubmitting ? <Loader size="small" /> : 'Submit'}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  const {
    user,
  } = state;

  return { user, appStyles: getStyles(state, props) };
};

const mapDispatchToProps = (dispatch, { context }) => {
  return {}
};


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReviewPopup);
